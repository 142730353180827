/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useQuery, gql } from '@apollo/client';

import { useStyles } from './style-select.js';

const GQL_LENSES = gql`
    query getLenses($make: String, $camera: String) { lenses(make: $make, camera: $camera) { _id LensModel Brand AnnouncedDate Weight photo_count }}
`;

export default function LensSelect(props) {
    const classes = useStyles();
    console.log(`lens default is:${JSON.stringify(props.default)}`)
    const [lens, setLens] = useState(props.default); // Lens selected via Select element
    const make = props.make
    const camera = props.camera
    console.log('lensSelect lens selected:'+JSON.stringify(lens))
    console.log('lensSelect make selected:'+JSON.stringify(make))
    console.log('lensSelect camera selected:'+JSON.stringify(camera))

    const { loading, error, data } = useQuery(GQL_LENSES,{variables: { make, camera }, skip: (make === props.make_default && camera === props.camera_default && props.static) });
    const lenses = (data && data.lenses) ? data.lenses : (props.static) ? props.static : []
    // console.log('lensSelect cameras:'+JSON.stringify(lenses))

    if (props.origin === '404') {
        useEffect(() => {
            console.log('lensSelect 404 query: '+JSON.stringify(props.default))
            setLens(props.default)
        }, [props.default])

    }

    function onLensSelect(value) {
        console.log('lensSelect onLensSelect: '+JSON.stringify(value))
        setLens(value)
        props.onChange(value)
    }

    console.log('render (lensSelect) gears: ' + lenses.length );
    // console.log('render (lensSelect) gears: ' + JSON.stringify(lenses) );

    // function sortLens(a, b) {
    //     let comparison = 0;
    //     if (a.photo_count > b.photo_count) {
    //         comparison = -1;
    //     } else if (a.photo_count < b.photo_count) {
    //         comparison = 1;
    //     }
    //     return comparison;
    // }

    function getOptionSelected(option, value) {
        return (option._id === value._id) ? true : false
    }

    function getOptionLabel(option) {
        // let option_s = `${option.LensModel} (${option.photo_count} photos)`
        let option_s = `${option.LensModel}`
        if (option.AnnouncedDate) {
            const announcedDate = new Date(Number(option.AnnouncedDate))
            option_s += ` (${announcedDate.getFullYear()})`
        }
        if (option.Weight) { option_s += ` (${option.Weight}g)` }
        return option_s
    }
    const options = lenses.map((option) => {
        const brand = option.Brand;
        return {
            brand: !brand ? 'Unidentified' : brand,
            ...option,
        };
    });
    return (
        <Autocomplete
            id={"photo-search-form-lens"}
            size="small"
            classes={{
                root: classes.root,
                option: classes.option,
            }}
            options={options.sort((a, b) => -b.brand.localeCompare(a.brand))}
            groupBy={(option) => option.brand}
            // options={switchOrderByPop ? lenses.slice().sort(sortLens) : lenses}
            value={lens}
            onChange={(event, value) => {onLensSelect(value)}}
            loading={loading}
            autoHighlight
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={"Choose a Lens"}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}
