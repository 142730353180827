import {makeStyles} from "@material-ui/core/styles";

export const useStylesSlider = makeStyles({
    root: {
        width: 300,
        display: "flex",
    },
    slider: {
        color: "#9b59b6",
    }
});
