/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useQuery, gql } from '@apollo/client';

import { useStyles } from './style-select.js';

const GQL_CAMERAS = gql`
    query getCameras($make: String) { cameras(make: $make) { 
        _id CameraModel CameraModelShort CameraModelAlts CameraModelFriendly Brand EffectivePixels AnnouncedDate SensorSize min_res max_res min_iso max_iso photo_count 
    }}
`;

export default function CameraSelect(props) {
    const classes = useStyles();
    console.log(`camera default is:${JSON.stringify(props.default)}`)
    const [camera, setCamera] = useState(props.default); // Camera selected via Select element
    const make = props.make
    console.log(`cameraSelect make selected: ${JSON.stringify(make)} default: ${props.make_default}`)

    const { loading, error, data } = useQuery(GQL_CAMERAS,{variables: { make }, skip: (make === props.make_default && props.static)});
    const cameras = (data && data.cameras) ? data.cameras : (props.static) ? props.static : []
    // console.log('cameraSelect cameras:'+JSON.stringify(cameras))

    if (props.origin === '404') {
        useEffect(() => {
            console.log('cameraSelect 404 query: ' + JSON.stringify(props.default))
            setCamera(props.default)
        }, [props.default])
    }

    function onCameraSelect(value) {
        console.log('cameraSelect onCameraSelect: '+JSON.stringify(value))
        setCamera(value)
        props.onChange(value)
    }

    function getOptionSelected(option, value) {
        return (option._id === value._id) ? true : false
    }

    function getOptionLabel(option) {
        // let option_s = `${option.CameraModel} (${option.photo_count}pics)`
        let option_s = `${option.CameraModelShort || option.CameraModel}`
        if (!make && option.Brand) {
            option_s += ` (${option.Brand})`
        }
        if (option.CameraModelAlts && option.CameraModelAlts.length > 0) {
            option_s += ` (${option.CameraModelAlts.join(', ')})`
        }
        // if (option.SensorSize) { option_s += ` (${option.SensorSize})` }
        if (option.AnnouncedDate) {
            const annoucedDate = new Date(Number(option.AnnouncedDate))
            option_s += ` (${annoucedDate.getFullYear()})`
        }
        return option_s
    }

    function compareSensorSize(a, b) {
        // console.log(`a vs b:${JSON.stringify(a)} vs ${JSON.stringify(b)}`)
        const sortedSensorSize = ["Medium format sensor", "Full frame sensor", "APS-H sensor", "APS-C sensor", "1.5″ sensor",
            "Four Thirds sensor", "1″ sensor", "Sensor smaller than 1″", "Sensor size unidentified"]
        const indexA = sortedSensorSize.indexOf(a.SensorSizeFmt)
        const indexB = sortedSensorSize.indexOf(b.SensorSizeFmt)
        if (indexA < indexB) {
            return -1
        } else if (indexA === indexB) {
            return 0
        } else if (indexA > indexB) {
            return 1
        } else {
            console.log('Something went wrong while sorting sensor size')
            return 1
        }
    }
    console.log('render (cameraSelect) gears: ' + cameras.length );
    // Check from time to time that this still handle all that is in DB
    // db.CamerasFE.distinct("SensorSize")
    const options = cameras.map((option) => {
        const smallerThanOneInch = [
            "1/3.2\"", "1/3.1\"", "1/2.7\"", "1/2.5\"", "1/3\"", "1/2.4\"", "1/2.35\"", "1/2.33\"", "1/2.3\"", "1/2\"",
            "1/1.8\"", "1/1.76\"", "1/1.75\"", "1/1.72\"", "1/1.7\"", "1/1.65\"", "1/1.63\"", "1/1.6\"", "2/3\""]
        const oneInchAndBigger = [ "1″", "1.5″", "Four Thirds", "APS-C", "APS-H", "Full frame", "Medium format"]
        const sensorSize = option.SensorSize;
        if (!sensorSize) {
            return {SensorSizeFmt: 'Sensor size unidentified', ...option}
        } else if (smallerThanOneInch.includes(sensorSize)) {
            return {SensorSizeFmt: 'Sensor smaller than 1″', ...option}
        } else {
            let postfix = ''
            if (["1″", "1.5″", "Four Thirds", "APS-C", "APS-H", "Full frame", "Medium format"].includes(sensorSize)) {postfix = ' sensor'}
            return {SensorSizeFmt: `${sensorSize}${postfix}`, ...option}
        };
    });

    return (
        <Autocomplete
            id={"photo-search-form-camera"}
            size="small"
            classes={{
                root: classes.root,
                option: classes.option,
            }}
            // options={cameras}
            // options={options.sort((a, b) => -b.sensorSize.localeCompare(a.sensorSize))}
            options={options.sort(compareSensorSize)}
            // renderOption={(option) => <Typography noWrap>{getOptionLabel(option)}</Typography>}
            groupBy={(option) => option.SensorSizeFmt}
            // renderGroup={renderGroup}
            value={camera}
            onChange={(event, value) => {onCameraSelect(value)}}
            loading={loading}
            autoHighlight
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={"Choose a Camera"}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}
