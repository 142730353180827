import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import {ZoomIn} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    iconAlign: {
        verticalAlign: 'middle',
        alignItems: 'center'
    }
}));

export default function WelcomeMessage(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    return (
        <div className={classes.root}>
            <Collapse in={open}>
                <Alert
                    severity="info"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    <Grid container direction="row" alignItems="center" >
                        <Typography>
                            <h2>Goat.vision allows you to inspect full size <i>photo samples</i> for thousands of cameras/lenses, to compare <i>sharpness</i> using SuperZoom (<ZoomIn className={classes.iconAlign} />) and review purchase decision (details in FAQ).</h2>
                        </Typography>
                    </Grid>
                </Alert>
            </Collapse>
        </div>
    );
}