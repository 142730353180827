import React, {useState} from "react";
import Slider from "@material-ui/core/Slider";
import Typography from '@material-ui/core/Typography';
import {useStylesSlider} from './style'

export default function IsoSlider(props) {
    const [iso, setIso] = useState();
    const classes = useStylesSlider();
    const defaultValue = [0,65000];

    function onIsoSelect(event) {
        console.log('callback IsoSlider:' + JSON.stringify(event));
        if (event) {
            props.onChange(event);
        }
    }

    console.log('IsoSlider min/max:'+props.availableRange.min+'/'+props.availableRange.max)
    return (
        <div className={classes.root}>
            <Typography id="range-slider"> {/*gutterBottom*/}
                ISO:&nbsp;&nbsp;
            </Typography>
            <Slider
                onChangeCommitted={(event, value) => {
                    onIsoSelect([Math.round(Math.pow(value[0],2)),Math.round(Math.pow(value[1],2))])
                }}
                classes={{
                    root: classes.slider,
                }}
                value={iso}
                min={Math.sqrt(Number(props.availableRange.min))}
                max={Math.sqrt(Number(props.availableRange.max))}
                scale={x => x * x}
                defaultValue={defaultValue}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                valueLabelFormat={(x) =>Math.round(x)}
            />
        </div>
    )
}