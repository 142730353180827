import React, {useState} from "react";
import Slider from "@material-ui/core/Slider";
import Typography from '@material-ui/core/Typography';
import {useStylesSlider} from './style'

function valuetext(value) {
    return `${value}MP`;
}

export default function ResSlider(props) {
    const [res, setRes] = useState();
    const classes = useStylesSlider();
    const defaultValue = [0,100];

    function onResSelect(event) {
        console.log('callback ResSlider:' + JSON.stringify(event));
        if (event) {
            props.onChange(event);
        }
    }

    console.log('ResSlider min/max:'+props.availableRange.min+'/'+props.availableRange.max)
    return (
        <div className={classes.root}>
            <Typography id="range-slider"> {/*gutterBottom*/}
                Res&nbsp;[MP]:&nbsp;&nbsp;
            </Typography>
            <Slider
                onChangeCommitted={(event, value) => {
                    onResSelect(value)
                }}
                classes={{ root: classes.slider }}
                value={res}
                min={props.availableRange.min}
                max={props.availableRange.max}
                defaultValue={defaultValue}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                // valueLabelFormat={valuetext}
            />
        </div>
    )
}