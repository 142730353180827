import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: 325,
        marginBottom: '10px',
        marginTop: '10px',
        fontSize: 12,
    },
    option: {
        fontSize: 12,
    },
    FormGroup: {

    },
    FormControlLabel: {
        marginLeft: '0px',
        alignSelf: 'start',
        borderBottom: 'none',
    },
    Switch: {
        color: "#9b59b6",
    },
    SwitchBackground: {
        color: "#9b59b6",
        backgroundColor: "#9b59b6",
    },
    p: {
        margin: 0,
    },
    div: {
        width: '300px',
        display: 'flex',
    },
    infoButton: {
        fontSize: 20,
        color: "#9b59b6",
        marginTop: "2px",
    }
}));
