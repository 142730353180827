import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {useStyles} from "./style-select";


export default function CameraOriginalOnlyFilter(props) {
    const classes = useStyles();
    const [onlyCo, setOnlyCo] = React.useState(false);
    const [toolTipSwitchOnlyCo, setToolTipSwitchOnlyCo] = React.useState(false);

    const handleToolTipSwitchOnlyCoClose = () => {
        setToolTipSwitchOnlyCo(false);
    };

    const handleToolTipSwitchOnlyCoOpen = () => {
        setToolTipSwitchOnlyCo(true);
    };

    function onFilterSwitch(event) {
        console.log('FilterSwitch onFilterSwitch: '+JSON.stringify(event.target.checked))
        setOnlyCo(event.target.checked)
        props.onChange(event.target.checked)
    }

    return (
        <div className={classes.div}>
            <p className={classes.p} >Only show camera original: </p>
            <Switch
                classes={{ // This is mess, need to manage theme/style properly ...
                    root: classes.Switch,
                    switchBase: classes.Switch,
                    thumb: classes.Switch,
                    track: classes.Switch,
                    checked: classes.Switch,
                    colorSecondary: classes.Switch,
                    sizeSmall: classes.Switch
                }} size="small" checked={onlyCo} onChange={onFilterSwitch} />
            <ClickAwayListener onClickAway={handleToolTipSwitchOnlyCoClose}>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleToolTipSwitchOnlyCoClose}
                    open={toolTipSwitchOnlyCo}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    placement="right"
                    title={
                        <React.Fragment>
                            Best-effort to detect whether photos<br/>were edited from camera original<br/>file based on EXIF metadata.
                        </React.Fragment>}
                >
                    <InfoOutlinedIcon fontSize="small" className={classes.infoButton} onClick={handleToolTipSwitchOnlyCoOpen} />
                </Tooltip>
            </ClickAwayListener>
        </div>
    );
}