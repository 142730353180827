import React from "react";
// import {useStylesSlider} from './style'
import { useStyles } from './style-select.js';
import { useQuery, gql } from '@apollo/client';
import Paper from "@material-ui/core/Paper";
import Collapse from '@material-ui/core/Collapse';
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { JSONLD, Product, Review, GenericNode } from "@researchgate/react-structured-data"

// TODO: Rewrite this awful file into something readable
// TODO: If mobile, default to collapsed
// TODO: Add "most popular on" metadata for Lens, showing
// TODO: Gatsby migration: Empty table should not show

const GQL_CAMERA_METADATA = gql`
    query getCamera($gear_id: String) { camera(camera: $gear_id) {
        CameraModel CameraModelFriendly EffectivePixels Brand AnnouncedDate MSRP SensorSize IsoMin IsoMax Weight photo_count
    }}
`;

const GQL_LENS_METADATA = gql`
    query getLens($gear_id: String) { lens(lens: $gear_id) {
        LensModel LensModelFriendly Brand AnnouncedDate MSRP Weight photo_count
    }}
`;

function formatCameraSpecs(raw_specs) {
    const specs = {}
    if (raw_specs.Brand) {specs['Brand'] = raw_specs.Brand}
    if (raw_specs.CameraModelFriendly) {specs['Model Name'] = raw_specs.CameraModelFriendly}
    if (raw_specs.LensModelFriendly) {specs['Model Name'] = raw_specs.LensModelFriendly} // It's either running for Camera or Lens
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"]
    console.log(`Date:::: ${raw_specs.AnnouncedDate}`)
    if (raw_specs.AnnouncedDate) {
        const AnnouncedDateDate = new Date(Number(raw_specs.AnnouncedDate))
        specs['Announced'] = `${AnnouncedDateDate.getFullYear()} ${monthNames[AnnouncedDateDate.getMonth()]}`
    }
    if (raw_specs.MSRP) {specs['RRP'] = `${raw_specs.MSRP} USD`}
    if (raw_specs.SensorSize) {specs['Sensor Size'] = raw_specs.SensorSize}
    if (raw_specs.EffectivePixels) {specs['Effective Pixels'] = `${raw_specs.EffectivePixels} MP`}
    if (raw_specs.IsoMin && raw_specs.IsoMax) {specs['ISO range'] = `${raw_specs.IsoMin} - ${raw_specs.IsoMax}`}
    if (raw_specs.Weight) {specs['Weight'] = `${raw_specs.Weight} grams`}
    return specs
}

export default function MetadataTable(props) {
    let gql_metadata = gql`query {camera {_id}}` // This is the closest to an empty query
    let gear_type = undefined
    let gear_id = undefined
    let gear_id_orig = undefined
    if (props.camera) {
        gear_id = props.camera
        gear_id_orig = (props.camera_specs) ? props.camera_specs._id : null
        gql_metadata = gql`${GQL_CAMERA_METADATA}`
        console.log(`metadataTable: props.camera_specs is ${JSON.stringify(props.camera_specs)}`)
        gear_type = 'Camera'
    } else if (props.lens) {
        gear_id = props.lens
        gear_id_orig = (props.lens_specs) ? props.lens_specs._id : null
        console.log(`metadataTable: props.lens_specs is ${JSON.stringify(props.lens_specs)}`)
        gql_metadata = gql`${GQL_LENS_METADATA}`
        gear_type = 'Lens'
    }
    console.log(`metadataTable: gear_id is ${gear_id} (${gear_type}) vs gear_id_orig: ${gear_id_orig}`)
    // const classes = useStylesSlider();
    const classes = useStyles();
    const { loading, error, data } = useQuery(gql_metadata,{variables: { gear_id }, ssr: false, skip: (gear_id === gear_id_orig)});
    const [open, setOpen] = React.useState(true);

    if (loading && !props.camera_specs && !props.lens_specs) {
        return(
            <Paper variant="outlined" className={classes.root}>
                {loading && `Loading ${gear_type} Specifications ...`}
            </Paper>
        )
    } else if (data || (props.camera_specs && gear_id && gear_id === gear_id_orig) || (props.lens_specs && gear_id && gear_id === gear_id_orig) ) {
        // console.log(`data.camera: ${JSON.stringify(data.camera)}`)
        // console.log(`data.lens: ${JSON.stringify(data.lens)}`)
        let gear_specs_raw
        if (data && data.camera || (props.camera_specs && gear_id === gear_id_orig)) {
            const c = (data && data.camera) ? data.camera : props.camera_specs
            if (Object.keys(c).filter(
                e => ['EffectivePixels', 'Brand', 'AnnouncedDate', 'MSRP', 'SensorSize', 'IsoMax', 'Weight'].includes(e)
            ).length > 0) {
                gear_specs_raw = c
                gear_specs_raw.ModelName = gear_specs_raw.CameraModel
                gear_specs_raw.ModelNameFriendly = gear_specs_raw.CameraModelFriendly
            } else { return ''}
        } else if (data && data.lens || (props.lens_specs && gear_id === gear_id_orig)) {
            const l = (data && data.lens) ? data.lens : props.lens_specs
            if (Object.keys(l).filter(
                e => ['Brand', 'AnnouncedDate', 'MSRP', 'Weight'].includes(e)
            ).length > 0) {
                gear_specs_raw = l
                gear_specs_raw.ModelName = gear_specs_raw.LensModel
                gear_specs_raw.ModelNameFriendly = gear_specs_raw.LensModelFriendly
            } else { return ''}
        } else { return ''}
        if (!gear_type && props.camera_specs) {gear_type = 'Camera'}
        if (!gear_type && props.lens_specs) {gear_type = 'Lens'}

        const gear_specs = formatCameraSpecs(gear_specs_raw)
        return (
            <>
            <JSONLD>
                <Product category={gear_type} name={gear_specs_raw.ModelNameFriendly && gear_specs_raw.ModelNameFriendly || gear_specs_raw.ModelName} model={gear_specs_raw.ModelName} description={gear_specs_raw.ModelNameFriendly}>
                    <GenericNode type="brand" jsonldtype="Brand" schema={{ name: gear_specs_raw.Brand }} />
                    <GenericNode type="weight" jsonldtype="Weight" schema={{ value: gear_specs_raw.Weight, unitCode: "GRM" }} />
                    <GenericNode type="review" jsonldtype="Review" reviewBody={`Compare ${gear_specs_raw.photo_count} photo samples using SuperZoom`} author="Goat vision" />
                </Product>
            </JSONLD>
            <Paper variant="outlined" className={classes.root}>
                <Collapse in={open} collapsedHeight={'15px'}>
                    <u>{gear_type} Specifications</u>
                    <span style={{float: 'right'}}>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {setOpen(!open)}}
                        align-items="flex-end"
                        align-content="flex-end"
                    >
                        {open && <ExpandMoreIcon style={{ fontSize: 10 }} />}
                        {!open && <ExpandLessIcon style={{ fontSize: 10 }} />}
                    </IconButton>
                    </span>
                    <table><tbody>
                    {Object.keys(gear_specs).map(key => (
                        // Without the `key`, React will fire a key warning
                        <React.Fragment key={key}>
                            <tr>
                            <td>{key}:</td>
                            <td>
                                {key === 'Model Name' && <h1>{gear_specs[key]}</h1> || gear_specs[key] }
                            </td>
                            </tr>
                        </React.Fragment>
                    ))}
                    </tbody></table>
                </Collapse>
            </Paper>
            </>
        );
    } else {
        return('')
    }
}
