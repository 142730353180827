import React, {useEffect, useState, useCallback, useRef} from 'react'
import { useLazyQuery, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Photo from './Photo.js';
import {trackCustomEvent} from "gatsby-plugin-google-analytics";
import GoogleAds from "./GoogleAds";
// TODO: handle img loading error: https://stackoverflow.com/questions/34097560/react-js-replace-img-src-onerror
// TODO: When remove all filter, it should show again "Select at least ..."

const GQL_PHOTOS = gql`
    query getPhotos(
        $pageSize: Int,
        $after: String,
        $make: String, 
        $camera: String, 
        $lens: String
        $aperture_min: Float,
        $aperture_max: Float,
        $exposure_min: Float,
        $exposure_max: Float,
        $iso_min: Int,
        $iso_max: Int,
        $post_processed: Boolean,
        $focal_min: Int,
        $focal_max: Int,
        $res_min: Int,
        $res_max: Int,
        ) { 
            photos(
                pageSize: $pageSize
                after: $after
                make: $make,
                camera: $camera,
                lens: $lens,
                aperture_min: $aperture_min,
                aperture_max: $aperture_max,
                exposure_min: $exposure_min,
                exposure_max: $exposure_max,
                iso_min: $iso_min,
                iso_max: $iso_max,
                post_processed: $post_processed,
                focal_min: $focal_min,
                focal_max: $focal_max,
                res_min: $res_min,
                res_max: $res_max,
            ) { 
                photos { 
                    _id owner CameraModel LensModel FocalLength ExposureTime FNumber ISO res farm server secret original_url
                },
            cursor
            hasMore
            resultCount
            }
        }
`;

// get_params = {'make': 'Make_id', 'camera': 'camera_id', 'lens': 'LensModel_id'}
// get_range_params = {'res': 'res', 'focal': 'FocalLength', 'aperture': 'FNumber', 'iso': 'ISO'}

export default function PhotoSearchResult(props) {
    const [photos, setPhotos] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [cursor, setCursor] = useState(undefined)
    const [toFetchMore, setToFetchMore] = useState(true)
    const [serm, setSerm] = useState('Select at least one Maker, Camera or Lens')
    const pageSize = 12
    console.log(`photoSearchResult rerender`)
    // console.log(`photoSearchResult - TOP: cursor:${cursor} hasMore:${hasMore} query:${JSON.stringify(props.query)}`)
    let start_ts = undefined;
    let start_ts_ = useRef();

    const [getPhotos, { loading, error, data, networkStatus }] = useLazyQuery(GQL_PHOTOS,{variables: {
        pageSize: pageSize, after:cursor,
        make: props.query.make, camera: props.query.camera, lens: props.query.lens
        }, skip: true,
        onCompleted: (data)=> {
            console.log(`getPhotos call completed ${data.photos.photos.length}`)
            if (data.photos.photos.length === 0) setPhotos([])
        },
        fetchPolicy: "no-cache"
    });
    console.log(`PhotoSearchResult loading beginning: ${loading}`)
    data && console.log(`PhotoSearchResult data beginning: ${data.photos.photos.length}`)

    // This is for inital load (above skip:true) + pagination
    function callFetchMore() {
        console.log(`PhotoSearchResult callFetchMore: ${JSON.stringify(props.query)}`);
        const query_gears = (({ make, camera, lens }) => ({ make, camera, lens }))(props.query);
        if (Object.values(query_gears).every(element => element == null)) {
            console.log('photoSearchResult - useEffect query: empty');
            setSerm('Select at least one Maker, Camera or Lens')
            return
        }
        setSerm('')

        if (!hasMore) {
            console.log(`photoSearchResult: no more to load`)
            return
        }
        console.log(`Calling fetchMore with: ${JSON.stringify({pageSize: 12, after: cursor, ...props.query})}`);

        start_ts_.current = Date.now();
        getPhotos({
            query: GQL_PHOTOS,
            variables: {
                pageSize: pageSize, after: cursor, ...props.query
            }, skip: false,
        })
    }
    useEffect(() =>{
        if (!data) return
        const search_timing = Date.now() - start_ts_.current;
        console.log(`photoSearchResult API resp time: ${search_timing}ms`);

        setSerm(`Found ${(Number(data.photos.resultCount) === 1000) ? 'more than' : ''} ${data.photos.resultCount} photos`)
        setCursor(data.photos.cursor)
        setHasMore(data.photos.hasMore)
        setPhotos(prevPhotos => [...new Set([...prevPhotos, ...data.photos.photos])])
    }, [data])

    const observer = useRef()
    const lastPhotoElementRef = useCallback(node =>{
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries =>{
            if(entries[0].isIntersecting && hasMore) {
                console.log('visible');
                setToFetchMore(true) // Using State to call FetchMore because props.query not accessible here.
            }
        })
        if (node) observer.current.observe(node)
        trackCustomEvent({
            category: "Photo",
            action: "ScrollToFetchMore",
        });
    }, [loading, hasMore])

    useEffect(() =>{
        setPhotos([]);
        setCursor(undefined)
        setHasMore(true)
        console.log('photoSearchResult - useEffect reset on query: ' + JSON.stringify(props.query));
        // callFetchMore();
        setToFetchMore(true)
    }, [props.query])

    useEffect(() =>{
        console.log(`ToFetchMore Effect: ${networkStatus} - ${loading}`)
        if (toFetchMore && !loading) callFetchMore();
        setToFetchMore(false)
    },[toFetchMore])

    // ============= Rendering Grid with info bar
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'block',  // flex
            'background-color': '#e6eaec',
            width: '100%',
//             'white-space': 'nowrap',
        },
    }));
    const classes = useStyles();
    function lastRef(photos, index) {
        if (photos.length === index + 1) {
            return lastPhotoElementRef
        }
    }

    if (loading && !cursor) {
        return (
            <div className={classes.root}>
                <div>{serm}</div>
                <div>{loading && 'Loading...'}</div>
            </div>
        )
    } else if (error) {
        return (
            <div className={classes.root}>{error}</div>
        )
    } else {
        return (
            <div className={classes.root}>
                <div>{serm}</div>
                {photos.map((photo, index) => (
                    <>
                        {index !== 0 && (index % 14) === 0 && <GoogleAds />}
                        <Photo ref={lastRef(photos, index)} meta={photo} key={photo._id} />
                    </>
                ))}
                <div>{loading && 'Loading...'}</div>
                <div>{hasMore == false && 'That\'s all that we have at moment!'}</div>
                <div>{error}</div>
            </div>
        );
    }
}