import React, {useState} from "react";
import Slider from "@material-ui/core/Slider";
import Typography from '@material-ui/core/Typography';
import {useStylesSlider} from './style'

const marks = [
    {value: 1, label: ''}, {value: 2, label: ''}, {value: 3, label: ''}, {value: 4, label: ''}, {value: 5, label: ''},
    {value: 6, label: ''}, {value: 7, label: ''}, {value: 8, label: ''}, {value: 9, label: ''}, {value: 10, label: ''},
    {value: 11, label: ''}, {value: 12, label: ''}, {value: 13, label: ''}, {value: 14, label: ''}, {value: 15, label: ''},
    {value: 16, label: ''}, {value: 17, label: ''}, {value: 18, label: ''}, {value: 19, label: ''}, {value: 20, label: ''},
    {value: 21, label: ''}, {value: 22, label: ''}, {value: 23, label: ''},
];

const mapping = {1: 0.000015625, 2: 0.00003125, 3: 0.0000625, 4: 0.000125, 5: 0.00025, 6: 0.0005, 7: 0.001,
    8: 0.002, 9: 0.004, 10: 0.008, 11: 0.0166666666667, 12: 0.0333333333333, 13: 0.0666666666667, 14: 0.125,
    15: 0.25, 16: 0.5, 17: 1, 18: 2, 19: 4, 20: 8, 21: 15, 22: 30, 23: 60}

function convertExposure(value) {
    return [mapping[value[0]], mapping[value[1]]]
}

function valueLabelFormat(value) {
    if (mapping[value] < 1) {
        let divider = Math.fround(1 / mapping[value])
        if (divider % 1000 == 0) {
            divider = String(divider).replace(/000$/, 'K');
        }
        return `1/${divider}`
    } else {
        return mapping[value]
    }
}

export default function ExposureSlider(props) {
    const [exposure, setExposure] = useState();
    const classes = useStylesSlider();
    const defaultValue = [1,23];

    // Unlike other Slider, we need to convert this to exposure values
    function onExposureSelect(event) {
        console.log('callback ExposureSlider:' + JSON.stringify(event));
        console.log('callback ExposureSlider (converted):' + JSON.stringify(convertExposure(event)));
        if (event) {
            props.onChange(convertExposure(event));
        }
    }

    return (
        <div className={classes.root}>
            <Typography id="range-slider"> {/*gutterBottom*/}
                Exposure&nbsp;[S]:&nbsp;&nbsp;
            </Typography>
            <Slider
                onChangeCommitted={(event, value) => {
                    onExposureSelect(value)
                }}
                classes={{
                    root: classes.slider,
                }}
                value={exposure}
                min={marks[0].value}
                max={marks[marks.length - 1].value}
                marks={marks}
                // getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                defaultValue={defaultValue}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                // valueLabelFormat={valuetext}
            />
        </div>
    )
}