import React, { useState, useEffect } from "react";
import './App.css';
import './style.css';
import logo from '../../images/gv-logo.png'
import MakerSelect from './makerSelect';
import CameraSelect from "./cameraSelect";
import LensSelect from "./lensSelect";
import ResSlider from "./resSlider";
import FocalSlider from "./focalSlider";
import ApertureSlider from "./apertureSlider";
import ExposureSlider from "./exposureSlider";
import IsoSlider from "./isoSlider";
import MetadataTable from "./metadataTable";
import CameraOriginalOnlyFilter from "./CameraOriginalOnlyFilter"
import PhotoSearchResult from "./PhotoSearchResult";
import WelcomeMessage from "./WelcomeMessage"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Obfuscate from 'react-obfuscate';

import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import BackToTop from "./BackToTop";

// Next plan
// TODO: Reduce page load time by not reloading if query already match URL path
// TODO: Change _ into - in URL path, find a good alternative for - e.g: X-T2 or 24-70
// TODO: Update <title>, <h1>, <h2> to for SEO
// TODO: Review SEO training google doc notes
// Before next release
// TODO: Remove CloudFront --> ErrorPages --> 404 Once fixed "blank page" when requesting: http://192.168.6.3:9000/search/Canon/Canon_EOS_550D/EF70-200mm_f%2F4L_USM/
//       and then validate with https://www.goat.vision/search/Canon/Canon_EOS_550D/EF70-200mm_f%2F4L_USM/
// TODO: Fix URL update when removing selection --> cannot reproduce
// TODO: Only display sample if certain condition
// TODO: Exclude bad camera / lens --> ~ done
// Nice to-have
// TODO: Check perf with react perf tool
// TODO: add expert mode: showing nearly all make/camera/lens
// TODO: exclude https://www.goat.vision/?camera=Google%20Pixel%202&lens=EF17-40mm%20f%2F4L%20USM&make=Google
// TODO: Write script to for each https://127.0.0.1:8081/api/photos/ ; get img and if 4xx && store id to delete.
// TODO: Add tabs URL
// TODO: Look at static-ify and generate custom heads --> https://www.gatsbyjs.org/tutorial/
// TODO: Add httpParams for slider filter in url
// TODO: filter out photos smaller than 1MPX

function App(props) {
    console.log(`App started, props: ${JSON.stringify(props)}`)
    // props.btc: build-time content, goal is to generate everything at build time to reduce # of GQL queries
    const maintenance = false;
    // Handle null make/camera/lens in props
    const make_id_default = props.context.makerSelected ? props.context.makerSelected._id : null
    const camera_id_default = props.context.cameraSelected ? props.context.cameraSelected._id : null
    const lens_id_default = props.context.lensSelected ? props.context.lensSelected._id : null

    // props are coming from gatsby-node.js but also pages/{404.js,index.js}
    const [query, setQuery] = useState({
        // make: "5ee7c485112b71f9ec7e1084",
        // make_name: "Canon",
        make: make_id_default,
        make_name: props.context.makerSelected ? props.context.makerSelected.Make : null,
        camera: camera_id_default,
        camera_name: props.context.cameraSelected ? props.context.cameraSelected.CameraModel : null,
        lens: lens_id_default,
        lens_name: props.context.lensSelected ? props.context.lensSelected.LensModel : null,
        res_min: null,
        res_max: null,
        focal_min: null,
        focal_max: null,
        aperture_min: null,
        aperture_max: null,
        exposure_min: null,
        exposure_max: null,
        iso_min: null,
        iso_max: null,
        post_processed: null,
    });
    const [slidersAvailRange, setSlidersAvailRange] = useState({
        res: {
            min: 0,
            max: 100,
        },
        focal: {
            min: 0,
            max: 1000,
        },
        aperture: {
            min: 0,
            max: 22,
        },
        iso: {
            min: 0,
            max: 65000,
        }
    })

    // if non-404 then props.context does not get updated so let's spare some CPU.
    if(props.context.origin === '404'){
        useEffect(() => {
            setQuery({...query, make: props.context.makerSelected ? props.context.makerSelected._id : null,
                make_name: props.context.makerSelected ? props.context.makerSelected.Make : null,
                camera: props.context.cameraSelected ? props.context.cameraSelected._id : null,
                camera_name: props.context.cameraSelected ? props.context.cameraSelected.CameraModel : null,
                lens: props.context.lensSelected ? props.context.lensSelected._id : null,
                lens_name: props.context.lensSelected ? props.context.lensSelected.LensModel : null})}, [props.context]
        )
    }

    // console.log('Params App3:'+JSON.stringify(window.location.pathname))
    // const history = useHistory();
    function setURL(urlPathElement) {
        function encodeUrlParam(value) {
            if (value == null) { return value }
            return value.replace(/ /g,'_').replace(/\//g,'⁄');
        }
        // TODO: Skip if query and context variables have identical query, to improve perf
        let urlPath = { // 'null' means selection was cleared where as 'undefined' means action was on different gear type.
            make: encodeUrlParam((urlPathElement.make !== undefined) ? urlPathElement.make : query.make_name),
            camera: encodeUrlParam((urlPathElement.camera !== undefined) ? urlPathElement.camera : query.camera_name),
            lens: encodeUrlParam((urlPathElement.lens !== undefined) ? urlPathElement.lens : query.lens_name)
        }

        console.log('App setURL query: '+JSON.stringify(query))
        console.log('App setURL urlPath: '+JSON.stringify(urlPath))
        console.log('App setURL urlPathElement: '+JSON.stringify(urlPathElement))
        let urlPathStr_ = `/${urlPath.make}/${urlPath.camera}/${urlPath.lens}/`
        console.log('App setURL4: '+JSON.stringify(urlPathStr_))
        urlPathStr_ = urlPathStr_.replace(/((null|undefined)\/)+$/g,'')
        urlPathStr_ = urlPathStr_.replace(/^\/([^\/]+)\/((null|undefined)\/)/,'/$1/any-cameras/')
        urlPathStr_ = urlPathStr_.replace(/^\/(null|undefined)\//,'/any-makers/')
        console.log('App setURL5: '+JSON.stringify(urlPathStr_))
        // navigate('/search'+urlPathStr_)
        // TODO: if window location is equal to what to set, skip
        const nextTitle = 'My new page title';
        const nextState = { additionalInformation: 'Updated the URL with JS' };
        window.history.pushState(nextState, nextTitle, '/search'+urlPathStr_);
    }

    // console.log('base url:'+JSON.stringify(window.location.hostname))

    function onMakeSelect(newValue) {
        if (!newValue) {newValue= {_id: null, Make: null}} // When Maker is cleared
        setQuery({...query, make: newValue._id, make_name: newValue.Make})
        setURL({make: newValue.Make})
        console.log('urlProcessor should be: '+JSON.stringify(newValue.Make))
        trackCustomEvent({
            category: "Search filtering",
            action: "Select Make",
            label: newValue.Make,
        });
    };
    function onCameraSelect(newValue) {
        if (!newValue) {
            newValue= {_id: null, CameraModel: null, min_res: 0, max_res: 100000000, min_iso: 0, max_iso: 65000}
        }
        console.log('APP onCameraSelect newValue:'+JSON.stringify(newValue))
        setQuery({...query, camera: newValue._id, camera_name: newValue.CameraModel})
        setURL({camera: newValue.CameraModel})
        console.log('APP onCameraSelect query:'+JSON.stringify(query))
        setSlidersAvailRange({...slidersAvailRange,
            res: {min: Math.floor(newValue.min_res / 1000000), max: Math.ceil(newValue.max_res / 1000000)},
            iso: {min: Number(newValue.min_iso), max: Number(newValue.max_iso)},
        })
        console.log(`APP onCameraSelect ISO: ${newValue.min_iso} / ${newValue.max_iso}`)
        console.log('APP onCameraSelect slidersAvailRange:'+JSON.stringify(slidersAvailRange))
        trackCustomEvent({
            category: "Search filtering",
            action: "Select Camera",
            label: newValue.CameraModel,
        });
    };
    function onLensSelect(newValue) {
        if (!newValue) {newValue= {_id: null, LensModel: null}}
        console.log('APP onLensSelect newValue:'+JSON.stringify(newValue))
        setQuery({...query, lens: newValue._id, lens_name: newValue.LensModel})
        setURL({lens: newValue.LensModel})
        trackCustomEvent({
            category: "Search filtering",
            action: "Select Lens",
            label: newValue.LensModel,
        });
    };
    function onCameraOriginalOnlySwitch(newValue) {
        if (newValue === undefined || newValue === null) return
        // If OnlyCameraOriginal is true, set post_processed to false
        if (newValue) {
            setQuery({...query, post_processed: !newValue})
        } else { // Set to OnlyCameraOriginal is false, include all photos
            setQuery({...query, post_processed: null})
        }
        trackCustomEvent({
            category: "Search filtering",
            action: "Switch CameraOriginalOnlyFilter",
            label: `${newValue}`,
        });
    };

    function onResSelect(newValue) {
        if (!newValue) return
        setQuery({...query, res_min: Number(newValue[0]) * 1000000, res_max: Number(newValue[1]) * 1000000})
        trackCustomEvent({
            category: "Search filtering",
            action: "Select Res",
            label: newValue[0] + ',' + newValue[1],
        });
    };

    function onFocalSelect(newValue) {
        if (!newValue) return
        setQuery({...query, focal_min: Number(newValue[0]), focal_max: Number(newValue[1])})
        trackCustomEvent({
            category: "Search filtering",
            action: "Select Focal",
            label: newValue[0] + ',' + newValue[1],
        });
    };

    function onApertureSelect(newValue) {
        if (!newValue) return
        setQuery({...query, aperture_min: Number(newValue[0]), aperture_max: Number(newValue[1])})
        trackCustomEvent({
            category: "Search filtering",
            action: "Select Aperture",
            label: newValue[0] + ',' + newValue[1],
        });
    };

    function onExposureSelect(newValue) {
        if (!newValue) return
        setQuery({...query, exposure_min: Number(newValue[0]), exposure_max: Number(newValue[1])})
        trackCustomEvent({
            category: "Search filtering",
            action: "Select Exposure",
            label: newValue[0] + ',' + newValue[1],
        });
    };

    function onIsoSelect(newValue) {
        if (!newValue) return
        setQuery({...query, iso_min: Number(newValue[0]), iso_max: Number(newValue[1])})
        trackCustomEvent({
            category: "Search filtering",
            action: "Select ISO",
            label: newValue[0] + ',' + newValue[1],
        });
    };

    function onTabSelect(event) {
        trackCustomEvent({
            category: "Tab Select",
            action: event.target.id,
        });
    }

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));
    const classes = useStyles();

    const m = "document.write('\u0065\u0073\u0070\u0061\u0063\u0069\u0061\u006c\u0040\u0070\u0072\u006f\u0074\u006f\u006e\u006d\u0061\u0069\u006c\u002e\u0063\u006f\u006d')";
    return (
<>
<div>
    <div class="title" id="back-to-top-anchor"><a href="/">Goat.vision</a><img src={logo} className="logo-title" alt="Goat.vision logo"/></div>
</div>
<div className="layout">
    <BackToTop />
    <input name="nav" type="radio" className="nav home-radio" id="home" onChange={onTabSelect} defaultChecked />
    <div className="page home-page">
        <WelcomeMessage />
        <div className="page-contents search-form">
            <Grid container alignItems='baseline' spacing={1}>
                <Grid item>
                    <MakerSelect onChange={onMakeSelect}
                                 origin={props.context.origin}
                                 default={props.context.makerSelected}
                                 static={props.btc.makes} />
                    <CameraSelect onChange={onCameraSelect}
                                  origin={props.context.origin}
                                  make={query.make}
                                  make_default={make_id_default}
                                  default={props.context.cameraSelected}
                                  static={props.btc.cameras} />
                    <LensSelect onChange={onLensSelect}
                                origin={props.context.origin}
                                make={query.make} camera={query.camera}
                                make_default={make_id_default}
                                camera_default={camera_id_default}
                                default={props.context.lensSelected}
                                static={props.btc.lenses} />
                    <Paper variant="outlined" className={classes.root}>
                        <CameraOriginalOnlyFilter onChange={onCameraOriginalOnlySwitch} />
                        <ResSlider onChange={onResSelect} availableRange={slidersAvailRange.res} />
                        <FocalSlider onChange={onFocalSelect} availableRange={slidersAvailRange.focal} />
                        <ApertureSlider onChange={onApertureSelect} availableRange={slidersAvailRange.aperture} />
                        <ExposureSlider onChange={onExposureSelect} availableRange={slidersAvailRange.exposure} />
                        <IsoSlider onChange={onIsoSelect} availableRange={slidersAvailRange.iso} />
                    </Paper>
                </Grid>
                <Grid item>
                    <MetadataTable camera={query.camera} camera_specs={props.btc.camera} />
                </Grid>
                <Grid item>
                    <MetadataTable lens={query.lens} lens_specs={props.btc.lens} />
                </Grid>
            </Grid>
            { maintenance &&
            <Backdrop className={classes.backdrop} open={true} >
                Database maintenance (with a very limited hosting budget), come back tomorrow please.
            </Backdrop>
            }
        </div>
        <div className="page-contents search-result">
            <PhotoSearchResult query={query} />
        </div>
    </div>
    <label className="nav" htmlFor="home">
    <span>
      <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
      Home
    </span>
    </label>
    <input name="nav" type="radio" className="faq-radio" id="faq" onChange={onTabSelect} />
    <div className="page faq-page">
        <div className="page-contents">
            <div className="subtitle">FAQ</div>
            <ul>
                <li>
                    <p className="question">What is this website for?</p>
                    <p>It's for fun. It's just a pet project that grew into something worth sharing, it comes with no guarantee though, see <a className="no-deco" href='/disclaimer.html' target="_blank">disclaimer</a>. That being said, I hope it can help discovering and comparing photo gears.</p>
                </li>
                <li>
                    <p className="question">Is this website related to any others?</p>
                    <p>No, this is an independent website not affiliated to any of third party website which are used to display images.</p>
                </li>
                <li>
                    <p className="question">Samples are nice but any recommendations for lens comparison?</p>
                    <ul>
                        <li><a href="https://www.dxomark.com/Lenses/" target="_blank">https://www.dxomark.com/Lenses</a>/</li>
                        <li><a href="https://www.dpreview.com/products/compare/lenses" target="_blank">https://www.dpreview.com/products/compare/lenses</a></li>
                        <li><a href="https://www.opticallimits.com/" target="_blank">https://www.opticallimits.com/</a></li>
                    </ul>
                </li>
                <li>
                    <p className="question">How accurate are Maker/Camera/Lens and others information?</p>
                    <p>At best they can only be as accurate as EXIF tags, these are usually accurate but I cannot exclude some where edited after the camera wrote them.</p>
                </li>
                <li>
                    <p className="question">Why do some lenses have multiple names?</p>
                    <p>Lens information are extracted from EXIF tags, different firmware versions sometimes use different name for a given lens or camera model. That's why brand name can be missing from lens name.</p>
                </li>
                <li>
                    <p className="question">I don't see the Camera or Lens that I'm looking for, is that it?</p>
                    <p>Searchable content is growing weekly so it's worth checking again regularly. Also I'd be happy to know what gear people are looking for so that I can better match visitors' expectations, please drop me an email (see About tab).</p>
                </li>
                <li>
                    <p className="question">Nice website but I'd like it to have ...</p>
                    <p>Yes, this is early stage, I have lots of ideas of things to add/improve but I'm unclear what are most wished, so again please drop me an email.</p>
                </li>
            </ul>
        </div>
    </div>
    <label className="nav" htmlFor="faq">
        <span>
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12" y2="17"></line></svg>
            FAQ
        </span>
    </label>
    <input name="nav" type="radio" className="about-radio" id="about" onChange={onTabSelect} />
    <div className="page about-page">
        <div className="page-contents">
            <div className="subtitle">Contact</div>
            <Obfuscate email="espacial@protonmail.com" />
            <div className="subtitle">Plans</div>
            <ul>
                <li><p>Curate more list of makers, cameras, lenses</p></li>
                <li><p>Grow photos database</p></li>
                <li><p>Grow gears database for more friendly model names instead of model code (from exif)</p></li>
                <li><p>Add push-on-green</p></li>
            </ul>
            <div className="subtitle">Changelog</div>
            <ul>
                <li><p>2021/04/25: Adding more cameras/drones (e.g: DJI Air 2S)</p></li>
                <li><p>2021/02/28: Adding Adsense in search results</p></li>
                <li><p>2021/02/09: Improve indexing of new cameras, including Sony A1 (ILCE-1)</p></li>
                <li><p>2021/01/26: Reduce # of API calls by generating more content at build time</p></li>
                <li><p>2021/01/26: Fixed inconsistent URL trailing slash issue </p></li>
                <li><p>2021/01/25: URL to Search Query refactor, considerably reduce JS exec time </p></li>
                <li><p>2021/01/19: Update FAQ with links</p></li>
                <li><p>2021/01/18: Minor performance & SEO improvements</p></li>
                <li><p>2021/01/03: Now showing metadata for most Sony and Zeiss lenses</p></li>
                <li><p>2021/01/02: Selecting Maker now filters Lenses available for selection, previously only Camera was filtering Lenses</p></li>
                <li><p>2020/12/28: Search result order is prioritizing camera original photos</p></li>
                <li><p>2020/12/28: Added floating button to go back to the top</p></li>
                <li><p>2020/12/27: Added model name for DJI drones, previously only showing model code, e.g: FCXXX</p></li>
                <li><p>2020/12/26: Clean-up pics with missing links</p></li>
                <li><p>2020/12/20: Added logo</p></li>
                <li><p>2020/12/06: Ported to Gatsby for static content</p></li>
                <li><p>2020/11/18: Show some specifications of Cameras and Lenses</p></li>
                <li><p>2020/11/18: Maker/Camera/Lens are now group by type, sensor size and brand respectively</p></li>
                <li><p>2020/10/17: Added switch to only show camera originals (not post-processed)</p></li>
                <li><p>2020/10/16: Added ExposureTime to search form</p></li>
                <li><p>2020/10/15: Added welcome message</p></li>
                <li><p>2020/08/31: Moved API to port 443 to prevent proxy error.</p></li>
                <li><p>2020/08/10: Rewrote Backend from REST to GraphQL.</p></li>
                <li><p>2020/07/15: Updated and curated database, now containing over 43 Millions photos.</p></li>
                <li><p>2020/07/13: SuperZoom feature! Allow to load full resolution picture for pixel inspection</p></li>
                <li><p>2020/07/12: ISO slider now use exponential scale</p></li>
                <li><p>2020/07/10: Changed from url params to url path</p></li>
                <li><p>2020/07/02: Added url params for url sharing</p></li>
                <li><p>2020/06/28: Added FocalLength, Aperture, ISO min/max to search form</p></li>
                <li><p>2020/06/21: Added Sliders with auto adjust min/max range based on</p></li>
                <li><p>2020/06/11: Major Refactor</p></li>
            </ul>
        </div>
    </div>
    <label className="nav" htmlFor="about">
        <span>
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
            About
        </span>
    </label>
</div>
</>
        );
}

export default App;
