import * as React from "react";
import {Zoom, useScrollTrigger, Fab} from "@material-ui/core";
import {KeyboardArrowUp} from "@material-ui/icons";
import { createMuiTheme } from '@material-ui/core/styles';
import {ThemeProvider} from "@material-ui/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#9b59b6',
            dark: '#8e44ad',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});

const style = {
    position: `fixed`,
    bottom: `5%`,
    right: `5%`,
    zIndex: `99`
};

const BackToTop = () => {
    const trigger = useScrollTrigger();

    const handleClick = event => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            "#back-to-top-anchor"
        );
        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    return (
        <ThemeProvider theme={theme}>
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" style={style}>
                <Fab color="primary" size="large" aria-label="scroll back to top">
                    <KeyboardArrowUp />
                </Fab>
            </div>
        </Zoom>
        </ThemeProvider>
    );
};

export default BackToTop;
