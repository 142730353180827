/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useStyles } from './style-select.js';

export default function MakerSelect(props) {
    console.log(`MakerSelect rendering...`)
    const classes = useStyles();
    console.log(`maker default is:${JSON.stringify(props.default)}`)
    const [make, setMake] = useState(props.default); // Maker selected via Select element

    console.log(`MakerSelect static is ${JSON.stringify(props.static)}`)
    const makes = props.static

    // If not build-time generated (aka 404) we need to update make selected once App resolves it from URL path
    if (props.origin === '404') {
        useEffect(() => {
            console.log('makerSelect 404 query: ' + JSON.stringify(props.default))
            setMake(props.default)
        }, [props.default])
    }

    function getOptionSelected(option, value) {
        return (option._id === value._id) ? true : false
    }

    function onMakeSelect(value) {
        console.log('setMake value:'+ JSON.stringify(value))
        setMake(value)
        props.onChange(value)
    }

    console.log(`MakerSelect rendered`)
    // Populate 'Category' property
    const options = makes.map((option) => {
        const MakerDeviceTypeMapping = {
            'Smartphone': ["Apple", "Google", "HUAWEI", "OnePlus", "Sony Ericsson", "Xiaomi"],
            'Dedicated Camera': ["Canon", "FUJIFILM", "GoPro", "Hasselblad", "LEICA", "LEICA CAMERA AG",
                "Leica Camera AG", "NIKON", "NIKON CORPORATION", "OLYMPUS CORPORATION", "OLYMPUS IMAGING CORP.",
                "PENTAX", "PENTAX Corporation", "PENTAX RICOH IMAGING", "Panasonic", "RICOH IMAGING COMPANY, LTD.",
                "SIGMA", "SONY"],
            'Smartphone & Dedicated Camera': ["SAMSUNG"],
            'Drone': ["DJI"]
        }
        for (const property in MakerDeviceTypeMapping) {
            if (MakerDeviceTypeMapping[property].includes(option.Make)) {
                return {Category: property, ...option}
            }
        }
        return {Category: 'Unknown', ...option}
    });
    return (
        <Autocomplete
            id={"photo-search-form-maker"}
            size="small"
            classes={{
                root: classes.root,
                option: classes.option,
            }}
            options={options.sort((a, b) => -b.Category.localeCompare(a.Category))}
            groupBy={(option) => option.Category}
            value={make}
            onChange={(event, value) => {onMakeSelect(value)}}
            autoHighlight
            getOptionLabel={(option) => option['Make']}
            getOptionSelected={getOptionSelected}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={"Choose a Maker (of camera)"}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}
