import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AdSense from 'react-adsense';

export default function GoogleAds(props) {

    const useStyles = makeStyles((theme) => ({
        photoContainer: {
            display: 'inline-block',
            // width: '100%',
            minWidth: '300px',
            minHeight: '300px',
            position: 'relative',
            'font-family': 'Arial',
            margin: '4px',
        },
    }));
    const classes = useStyles();

    return(
        <div className={classes.photoContainer}>
            {/*<script async src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'/>*/}
            {/*<HTMLComment text='ads_display_responsive1' />*/}
            {/*<ins className='adsbygoogle ads_slot'*/}
            {/*     style={{display: 'block'}}*/}
            {/*     data-ad-client='ca-pub-5594470880929919'*/}
            {/*     data-ad-slot='7432034164'*/}
            {/*     data-ad-format='auto'*/}
            {/*     data-full-width-responsive='true' />*/}
            {/*<script>*/}
            {/*    {'(adsbygoogle = window.adsbygoogle || []).push({});'}*/}
            {/*</script>*/}

            {/*<script async src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js' />*/}
            {/*<HTMLComment text="image frame fix" />*/}
            {/*<ins className='adsbygoogle ads_slot'*/}
            {/*     style={{display: 'inline-block;width:500px;height:333px'}}*/}
            {/*     data-ad-client='ca-pub-5594470880929919'*/}
            {/*     data-adtest='on'*/}
            {/*     data-ad-slot='7934882450' />*/}
            {/*<script>*/}
            {/*    {'(adsbygoogle = window.adsbygoogle || []).push({});'}*/}
            {/*</script>*/}

            {/*<script async src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js' />*/}
            {/*<HTMLComment text="image frame responsive" />*/}
            {/*<ins className='adsbygoogle ads_slot'*/}
            {/*     style={{display: 'block'}}*/}
            {/*     data-ad-client='ca-pub-5594470880929919'*/}
            {/*     data-ad-slot='3593178739'*/}
            {/*     data-ad-format='auto'*/}
            {/*     data-adtest='on'*/}
            {/*     data-full-width-responsive='true' />*/}
            {/*<script>*/}
            {/*    {'(adsbygoogle = window.adsbygoogle || []).push({});'}*/}
            {/*</script>*/}
            {/*<AdSense.Google*/}
            {/*    client='ca-pub-5594470880929919'*/}
            {/*    slot='7934882450'*/}
            {/*    style={{ width: 500, height: 300, float: 'left' }}*/}
            {/*    format=''*/}
            {/*/>*/}
            <AdSense.Google
                client='ca-pub-5594470880929919'
                slot='3593178739'
                style={{ display: 'block' }}
                format='rectangle, horizontal'
                responsive='true'
                data-full-width-responsive='true'
            />
        </div>
    )
}
