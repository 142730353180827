import React, {useState} from "react";
import Slider from "@material-ui/core/Slider";
import Typography from '@material-ui/core/Typography';
import {useStylesSlider} from './style'

export default function ApertureSlider(props) {
    const [aperture, setAperture] = useState();
    const classes = useStylesSlider();
    const defaultValue = [0,22];

    function onApertureSelect(event) {
        console.log('callback ApertureSlider:' + JSON.stringify(event));
        if (event) {
            props.onChange(event);
        }
    }

    console.log('ApertureSlider min/max:'+props.availableRange.min+'/'+props.availableRange.max)
    return (
        <div className={classes.root}>
            <Typography id="range-slider"> {/*gutterBottom*/}
                Aperture&nbsp;[F]:&nbsp;&nbsp;
            </Typography>
            <Slider
                onChangeCommitted={(event, value) => {
                    onApertureSelect(value)
                }}
                classes={{
                    root: classes.slider,
                }}
                value={aperture}
                min={props.availableRange.min}
                max={props.availableRange.max}
                step={0.1}
                defaultValue={defaultValue}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                // valueLabelFormat={valuetext}
            />
        </div>
    )
}