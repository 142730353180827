import React, {useState, forwardRef} from 'react'
import IconButton from '@material-ui/core/IconButton';
import { Link, ZoomIn } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import { Magnifier, MOUSE_ACTIVATION, TOUCH_ACTIVATION} from "react-image-magnifiers";
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// TODO: hide missing pics: https://www.goat.vision/search/SONY/ILCE-6500/150-600mm_F5-6.3_DG_OS_HSM_%7C_Contemporary_015/
// Custom change to react-image-magnifiers:
// [~/Code/gitHub/react-image-magnifiers] --> npm link ../../goat.vision/react-frontend/node_modules/react
// [~/Code/goat.vision/react-frontend] --> npm link "react-image-magnifiers"
// [~/Code/gitHub/react-image-magnifiers] --> npm run build ; npm run transpile

const Photo = forwardRef((props, ref) => {
    // console.log('Photo meta: '+JSON.stringify(props.meta))

    const [largeImageSrc, setLargeImageSrc] = useState(null)
    const [largeImageLoading, setLargeImageLoading] = useState(false)
    const [largeImageLoadingCompleted, setLargeImageLoadingCompleted] = useState(false)
    const [loadingCompletedFade, setLoadingCompletedFade] = useState(true)
    const [alertDetails, setAlertDetails] = useState(undefined)

    function magnifierToggleHandler(photoId, sourceUrl) {
        if (largeImageSrc) {displayAlert(true); return;}
        setLargeImageSrc(sourceUrl);
        setLargeImageLoading(true);
        trackCustomEvent({
            category: "Photo",
            action: "SuperZoom",
        });
    }
    function onLargeImageLoad() {
        if (!largeImageSrc) return
        console.log('Photo onLargeImageLoad: '+JSON.stringify(largeImageSrc))
        setLargeImageLoading(false);
        displayAlert(false);
    }
    function displayAlert(reClicked) {
        if (reClicked) {
            setAlertDetails({severity:'info', title:'Already downloaded', msg:'Full resolution is ready!'})
        } else {
            setAlertDetails({severity:'success', title:'Success', msg:'Full resolution picture downloaded!'})
        }
        setLargeImageLoadingCompleted(true);
        setLoadingCompletedFade(true);
        console.log('show success alert')
        // Fade out and remove alert box to allow click to zoom-in
        const timer1 = setTimeout(() => {
            setLoadingCompletedFade(false)
        }, 2000);
        const timer2 = setTimeout(() => {
            setLargeImageLoadingCompleted(false);
        }, 2500);
        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }

    function specPrint(value, unit) {
        const u = unit ? unit : ''
        if (value) return String(value) + u
        else return 'N/A'
    }

    function round(value, precision) {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    const useStyles = makeStyles((theme) => ({
        photoContainer: {
            display: 'inline-block',  // flex
            // width: '100%',
            // height: '100%',
            position: 'relative',
            'font-family': 'Arial',
            margin: '4px',
        },
        image: {
            width: '100%',
        },
        icon: {
            color: 'white',
            fontSize: '26px',
        },
        textContainer: {
            position: 'absolute',
            width: '100%',
            bottom: '0px',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            color: 'white',
            paddingBottom: '0px',
            fontSize: '13px',
        },
        textBlockIcon: {
            display: 'flex',
            justifyContent: 'center',
            fontSize: '19.5px',
        },
        circularProgress: {
            color: 'white',
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 'auto',
            marginBottom: 'auto',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1,
            opacity: 0.8,
        },
        alertProgress: {
            color: 'white',
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 'auto',
            marginBottom: 'auto',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1,
            opacity: 0.8,
            textAlign: 'center',
            fontWeight: 'bold',
            height: '100px',
            width: '300px',
        },
    }));
    const classes = useStyles();

    return(
        <div ref={ref} key={props.meta._id} className={classes.photoContainer} >
            <Magnifier className={classes.image}
                       imageSrc={'https://farm' + props.meta.farm + '.staticflickr.com/' + props.meta.server + '/' + props.meta._id + '_' + props.meta.secret + '.jpg'}
                       largeImageSrc={largeImageSrc}
                       imageAlt={props.meta._id}
                       mouseActivation={MOUSE_ACTIVATION.CLICK} // Optional
                       touchActivation={TOUCH_ACTIVATION.TAP} // Optional
                       dragToMove={true}
                       onLargeImageLoad={onLargeImageLoad}
            />
            {largeImageLoading && <CircularProgress size={68} className={classes.circularProgress} />}
            {largeImageLoadingCompleted &&
                <div className={classes.alertProgress} >
                    <Fade in={loadingCompletedFade}>
                        <Alert variant="filled"  severity={alertDetails.severity} >
                            <AlertTitle>{alertDetails.title}</AlertTitle>
                            {alertDetails.msg}<br/><strong>Click to zoom-in!</strong>
                        </Alert>
                    </Fade>
                </div>
            }
            <div className={classes.textContainer}>
                <div>
                    <span>Camera: {specPrint(props.meta.CameraModel)} </span>
                    <span>Lens: {specPrint(props.meta.LensModel)}</span><br/>
                    <span>Focal:{specPrint(Math.round(props.meta.FocalLength), 'mm')} </span>
                    <span>Exp:{specPrint(props.meta.ExposureTime, 's')} </span>
                    <span>F:{round(props.meta.FNumber,1)} </span>
                    <span>ISO:{specPrint(props.meta.ISO)} </span>
                    <span>Res:{Math.round(props.meta.res / 1000000)}MP </span>
                    {/*<span>ID:{props.meta._id}</span>*/}
                </div>
                <div className={classes.textBlockIcon}>
                    <Tooltip title="SuperZoom! This will download full resolution photo for 1:1 pixel inspection. Click this magnifying glass, wait, click photo to zoom in, click and drag to move, click to zoom out." arrow>
                        <IconButton onClick={() => {magnifierToggleHandler(props.meta._id, props.meta.original_url)}} size="small" color="primary" aria-label="SuperZoom" >
                            <ZoomIn className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Link to full resolution photo." arrow>
                        <IconButton href={`https://www.flickr.com/photos/${props.meta.owner}/${props.meta._id}/`} target="_blank" size="small" color="primary" aria-label="full resolution photo link" >
                            <Link className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    )

});
export default Photo