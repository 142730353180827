/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// TODO: check that canonical link https://www.goat.vision/ has a trailing slash .replace ...
// TODO: add '| Goat.vision' to <title> looks like most pages have it or e.g: "Full-size sample photos from Fujifilm X-A1 - Pixel Peeper"
// TODO: meta-data to include friendly name, aka model name instead of code name e.g: FCXXX vs Mavic Air 2
// TODO: add <link rel="canonical" href={url} />
// TODO: add <meta property="og:url" content={url} />

function SEO({ context }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            adsense_id
          }
        }
      }
    `
  )
  const make_name = context.makerSelected ? (context.makerSelected.Make ? context.makerSelected.Make : context.makerSelected) : '' // depends
  const camera_name = context.cameraSelected ? context.cameraSelected.CameraModel : ''
  const camera_short_name = context.cameraSelected ? context.cameraSelected.CameraModelShort : ''
  const camera_model_alts = (context.cameraSelected && context.cameraSelected.CameraModelAlts) ? context.cameraSelected.CameraModelAlts : ''
  const lens_name = context.lensSelected ? context.lensSelected.LensModel : ''

  let title = 'Full size camera and lens sample images for sharpness comparison | Goat.vision'
  const titleFragments = [make_name, camera_short_name, camera_model_alts, lens_name]
  if (titleFragments.some((i) => {return i})) {
    title = `${titleFragments.filter(Boolean).join(" - ")} sample images | Goat.vision`
  }

  const description = `Full-size sample photos of ${[make_name, camera_name, camera_model_alts, lens_name].filter(Boolean).join(" - ")} to filter by resolution, focal length, exposure time, aperture and ISO`
  const lang = `en`

  console.log(`seo.js site.siteMetadata.siteUrl: ${site.siteMetadata.siteUrl}`)
  console.log(`seo.js context.canonicalUrlPath: ${context.canonicalUrlPath}`)
  const canonicalUrl = (context.canonicalUrlPath) ? `${site.siteMetadata.siteUrl}${context.canonicalUrlPath}`.replace(/\/?$/, '/') : `${site.siteMetadata.siteUrl}`.replace(/\/?$/, '/')

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `Keywords`,
          content: `${[make_name, camera_name, camera_model_alts, lens_name].filter(Boolean).join(",")},review,sharpness,full,size,compare,photo,image,sample,aperture,iso,focal,camera,lens,drone,DSLR,SLR,mirrorless,pixel`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}/site-thumbnail.jpg`,
        },
        {
          property: `og:url`,
          content: `${site.siteMetadata.siteUrl}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`, // summary
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata?.author || ``,
        // },
        {
          name: `twitter:site`,
          content: `@goatvision3`,
        },
        {
          name: `twitter:image:alt`,
          content: `Search form to compare full size resolution photo samples for camera or lens and more`
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `google-site-verification`,
          content: `Ubw69U7NVv7zeB-dPJkq32Q74Zdio5CoaeoB7rWQp-w`,
        },
        {
          name: `yandex-verification`,
          content: `efe25a69a8878f4e`,
        },
        {
          name: `robots`,
          content: "index, follow",
        },
        {
          name: `googlebot`,
          content: "all",
        },
      ]}
    >
      <link rel='canonical' href={canonicalUrl} />
      {/*<script data-ad-client={site.siteMetadata.adsense_id} async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />*/}
    </Helmet>
  )
}

export default SEO
